import {
  IconCalendarDown,
  IconCalendarUp,
  IconSortAscendingLetters,
  IconSortAscendingNumbers,
  IconSortDescendingLetters,
  IconSortDescendingNumbers,
} from '@tabler/icons-react';
import { fromPairs } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type TablerIconElement } from '@amalia/design-system/components';

const buildOptions = (
  labelAscending: string,
  labelDescending: string,
  iconAscending: TablerIconElement,
  iconDescending: TablerIconElement,
) => [
  {
    value: 'ASC' as const,
    label: labelAscending,
    filterLabel: 'asc',
    icon: iconAscending,
  },
  {
    value: 'DESC' as const,
    label: labelDescending,
    filterLabel: 'desc',
    icon: iconDescending,
  },
];

export const useBuildSortOptionsForFormat = () => {
  const { formatMessage } = useIntl();

  return useCallback(
    (format: FormatsEnum) => {
      switch (format) {
        case FormatsEnum.date:
        case FormatsEnum['date-time']:
          return buildOptions(
            formatMessage({ defaultMessage: 'Oldest to newest' }),
            formatMessage({ defaultMessage: 'Newest to oldest' }),
            <IconCalendarUp />,
            <IconCalendarDown />,
          );
        case FormatsEnum.currency:
        case FormatsEnum.percent:
        case FormatsEnum.number:
          return buildOptions(
            formatMessage({ defaultMessage: 'Lowest to highest' }),
            formatMessage({ defaultMessage: 'Highest to lowest' }),
            <IconSortAscendingNumbers />,
            <IconSortDescendingNumbers />,
          );

        default:
          return buildOptions(
            formatMessage({ defaultMessage: 'A to Z' }),
            formatMessage({ defaultMessage: 'Z to A' }),
            <IconSortAscendingLetters />,
            <IconSortDescendingLetters />,
          );
      }
    },
    [formatMessage],
  );
};

export const useSortOptionsByFormat = () => {
  const buildSortOptionsForFormat = useBuildSortOptionsForFormat();

  return useMemo(
    () => fromPairs(Object.values(FormatsEnum).map((format) => [format, buildSortOptionsForFormat(format)])),
    [buildSortOptionsForFormat],
  );
};
