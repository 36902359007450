import { memo, useCallback, useEffect, useState, type FocusEvent as ReactFocusEvent } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { Dropdown, type DropdownProps } from '../dropdown/Dropdown';
import { type PopoverProps } from '../popover/Popover';

import { useCheckedOptionsValues } from './hooks/useCheckedOptionsValues';
import { useFilterOptions } from './hooks/useFilterOptions';
import { useFlattenOptions } from './hooks/useFlattenOptions';
import { useHasEveryOptionSelected } from './hooks/useHasEveryOptionSelected';
import { useHasSomeOptionSelected } from './hooks/useHasSomeOptionSelected';
import { useStateHandlers } from './hooks/useStateHandlers';
import { useStateWithOptions } from './hooks/useStateWithOptions';
import { PaginatedSelectDropdownItemsList } from './paginated-select-dropdown-items-list/PaginatedSelectDropdownItemsList';
import { SelectDropdownGroup } from './select-dropdown-group/SelectDropdownGroup';
import { SelectDropdownItem } from './select-dropdown-item/SelectDropdownItem';
import { SelectDropdownSelectAllItem } from './select-dropdown-select-all-item/SelectDropdownSelectAllItem';
import { selectDropdownTestIds } from './SelectDropdown.testIds';
import {
  type SelectOptionGroup,
  type SelectDropdownOption,
  isSelectOptionGroup,
  type SelectDropdownStateProps,
  type SelectDropdownChildRenderProps,
  type SelectDropdownValue,
} from './SelectDropdown.types';

export type SelectDropdownProps<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = MergeAll<
  [
    Omit<DropdownProps, 'bodyRef' | 'content' | 'searchInput'>,
    SelectDropdownStateProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable>,
    {
      /** List of options and groups of options. */
      options: (TGroup | TOption)[];
      /** Controlled search text. If not provided, SelectDropdown will use its internal search text state. */
      searchText?: string;
      /** Controlled search text change handler. */
      onChangeSearchText?: (searchText: string) => void;
      /** Placeholder for the search input. */
      searchInputPlaceholder?: string;
      /** Should hide the search input (e.g. if you want to handle filtering yourself). By default, the search input is hidden if there are too few options. */
      hideSearchInput?: boolean;
      /** Override the default filter predicate. */
      filterOption?: (option: TOption, searchText: string) => boolean;
      /** Should should the menu when selecting an option. */
      shouldCloseMenuOnSelectOption?:
        | boolean
        | ((
            option: TIsClearable extends false | undefined
              ? NonNullable<SelectDropdownValue<TOption, TIsMultiple, true>>
              : SelectDropdownValue<TOption, TIsMultiple, true>,
          ) => boolean);
      /** Dropdown anchor. */
      children:
        | PopoverProps['children']
        | ((props: SelectDropdownChildRenderProps<TOption, TIsMultiple>) => PopoverProps['children']);
    },
  ]
>;

const SelectDropdownBase = function SelectDropdown<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>({
  value: propsValue,
  onChange: propsOnChange,
  options,
  isMultiple,
  useOptionAsValue,
  isClearable,
  searchText: controlledSearchText,
  onChangeSearchText: setControlledSearchText,
  searchInputPlaceholder,
  hideSearchInput,
  filterOption,
  children,
  shouldCloseMenuOnSelectOption = !isMultiple,
  isOpen: controlledIsOpen,
  onChangeIsOpen: setControlledIsOpen,
  onBlur,
  ...props
}: SelectDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>) {
  // Search text can either be controlled or uncontrolled.
  // Internal state is not synced with props, if the search text is controlled it should stay controlled.
  const [uncontrolledSearchText, setUncontrolledSearchText] = useState('');
  const searchText = controlledSearchText ?? uncontrolledSearchText;
  const onChangeSearchText = setControlledSearchText ?? setUncontrolledSearchText;

  // Dropdown can either be controlled or uncontrolled.
  // Internal state is not synced with props, if the dropdown is controlled it should stay controlled.
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;
  const setIsOpen = setControlledIsOpen ?? setUncontrolledIsOpen;

  const handleChangeIsOpen = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen);

      // We want to blur the select field when the dropdown is closed and not when the triggered is blurred.
      // Otherwise we would blur the select field with the mousedown on the option we want to select, which makes the layout shift.
      // The event is mocked because we can't do better.
      if (!newIsOpen) {
        setTimeout(() => onBlur?.({ target: { name: '' } } as unknown as ReactFocusEvent<HTMLDivElement>), 0);
      }
    },
    [onBlur, setIsOpen],
  );

  // Remove empty groups.
  const filteredOptionsOrGroups = useFilterOptions<TOption, TGroup>(options, searchText, filterOption);

  // Get all options in a flat list to make state changes easier.
  const flatOptions = useFlattenOptions<TOption, TGroup>(options);

  const handleChange: NonNullable<typeof propsOnChange> = useCallback(
    (...args) => {
      propsOnChange?.(...args);
      if (typeof shouldCloseMenuOnSelectOption === 'function') {
        handleChangeIsOpen(
          !shouldCloseMenuOnSelectOption(args[0] as Parameters<typeof shouldCloseMenuOnSelectOption>[0]),
        );
      } else if (shouldCloseMenuOnSelectOption) {
        handleChangeIsOpen(false);
      }
    },
    [propsOnChange, shouldCloseMenuOnSelectOption, handleChangeIsOpen],
  );

  // Wrap value/onChange to handle value as options everywhere.
  const { value, onChange } = useStateWithOptions<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable>({
    value: propsValue,
    onChange: handleChange,
    options: flatOptions,
    isMultiple,
    useOptionAsValue,
  });

  // Get values as a list of TOption['value'] to make it easier to find which options are checked.
  const checkedOptionsValues = useCheckedOptionsValues<TOption, TIsMultiple, TIsClearable>({ isMultiple, value });

  const hasSomeOptionSelected = useHasSomeOptionSelected(flatOptions, checkedOptionsValues);
  const hasEveryOptionSelected = useHasEveryOptionSelected(flatOptions, checkedOptionsValues);

  const { handleSelectAllChange, handleClear, handleGroupSelectAllChange, handleOptionChange } = useStateHandlers<
    TOption,
    TIsMultiple,
    TIsClearable,
    TGroup
  >({
    isMultiple,
    isClearable,
    options: flatOptions,
    value,
    onChange,
    checkedOptionsValues,
  });

  // Hide select all if there is only one option or group, or if not in multiple mode, or if there is an active search, or if every option is disabled.
  const hideSelectAll = (optionsAndGroups: (TGroup | TOption)[], groupFlatOptions: TOption[]) =>
    !!(optionsAndGroups.length < 2 || !isMultiple || searchText || groupFlatOptions.every((option) => option.disabled));

  useEffect(() => {
    if (!isOpen) {
      onChangeSearchText('');
    }
  }, [isOpen, onChangeSearchText]);

  const hasNoGroups = !filteredOptionsOrGroups.some(isSelectOptionGroup);

  // Automatically hide search input if there are less than 6 flat options (in single mode) or 5 flat options (in multiple mode) and no groups.
  // This behavior can be overridden by explicitely setting hideSearchInput.
  const shouldHideSearchInput = hideSearchInput ?? (hasNoGroups && flatOptions.length <= (isMultiple ? 5 : 6));

  return (
    <Dropdown
      {...props}
      isOpen={isOpen}
      searchText={searchText}
      content={
        filteredOptionsOrGroups.length ? (
          <div
            data-testid={props.id ? selectDropdownTestIds.listbox(props.id) : undefined}
            role="listbox"
          >
            {!hideSelectAll(filteredOptionsOrGroups, flatOptions) && (
              <SelectDropdownSelectAllItem
                checked={hasEveryOptionSelected}
                data-testid={selectDropdownTestIds.globalSelectAll}
                indeterminate={!hasEveryOptionSelected && hasSomeOptionSelected}
                onChange={handleSelectAllChange}
              />
            )}

            {hasNoGroups ? (
              <PaginatedSelectDropdownItemsList<TOption, TIsMultiple>
                checkedOptionsValues={checkedOptionsValues}
                isMultiple={isMultiple}
                options={filteredOptionsOrGroups as TOption[]}
                onOptionChange={handleOptionChange}
              />
            ) : (
              filteredOptionsOrGroups.map((optionOrGroup) =>
                isSelectOptionGroup(optionOrGroup) ? (
                  <SelectDropdownGroup<TOption, TIsMultiple, TGroup>
                    key={optionOrGroup.options[0].key ?? String(optionOrGroup.options[0].value)}
                    checkedOptionsValues={checkedOptionsValues}
                    data-testid={selectDropdownTestIds.groupContainer(optionOrGroup.options[0].value)}
                    group={optionOrGroup}
                    hideSelectAll={hideSelectAll(optionOrGroup.options, optionOrGroup.options)}
                    isCollapsible={filteredOptionsOrGroups.length > 1}
                    isMultiple={isMultiple}
                    onOptionChange={handleOptionChange}
                    onSelectAllChange={handleGroupSelectAllChange}
                  />
                ) : (
                  <SelectDropdownItem<TOption, TIsMultiple>
                    key={optionOrGroup.key ?? String(optionOrGroup.value)}
                    checked={checkedOptionsValues.includes(optionOrGroup.value)}
                    isMultiple={isMultiple}
                    option={optionOrGroup}
                    onChange={handleOptionChange}
                  />
                ),
              )
            )}
          </div>
        ) : null
      }
      searchInput={
        !shouldHideSearchInput ? (
          <Dropdown.SearchInput
            placeholder={searchInputPlaceholder}
            value={searchText}
            onChange={onChangeSearchText}
          />
        ) : undefined
      }
      onChangeIsOpen={handleChangeIsOpen}
    >
      {typeof children === 'function'
        ? children({
            value,
            onClear: handleClear,
            isDropdownOpen: isOpen,
            hasEveryOptionSelected,
            flatOptions,
          })
        : children}
    </Dropdown>
  );
};

export const SelectDropdown = memo(SelectDropdownBase) as typeof SelectDropdownBase;
