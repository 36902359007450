import {
  DataConnectorTypes,
  DataConnectorCategories,
  type ConnectorResponse,
} from '@amalia/data-capture/connectors/types';
import {
  type BaseCustomObjectDefinition,
  CustomObjectDefToDataConnectorType,
} from '@amalia/data-capture/record-models/types';

export interface ConnectorDefinition {
  name: string;
  connectorType: DataConnectorTypes;
  maxFilters: number;
  category: DataConnectorCategories;
  isRealTimeSynchronizationSupported?: boolean;
}

export const CONNECTORS: Record<DataConnectorTypes, ConnectorDefinition> = {
  [DataConnectorTypes.SALESFORCE]: {
    name: 'Salesforce',
    connectorType: DataConnectorTypes.SALESFORCE,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: true,
  },
  [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT]: {
    name: 'Salesforce',
    connectorType: DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: false,
  },
  [DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT]: {
    name: 'Salesforce',
    connectorType: DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: false,
  },
  [DataConnectorTypes.HUBSPOT]: {
    name: 'HubSpot',
    connectorType: DataConnectorTypes.HUBSPOT,
    category: DataConnectorCategories.DATA,
    // it's 3 but we add another one by default
    maxFilters: 2,
  },
  [DataConnectorTypes.NETSUITE]: {
    name: 'NetSuite',
    connectorType: DataConnectorTypes.NETSUITE,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.GOOGLESHEET]: {
    name: 'Google Sheets',
    connectorType: DataConnectorTypes.GOOGLESHEET,
    category: DataConnectorCategories.DATA,
    maxFilters: 0,
  },
  [DataConnectorTypes.REDSHIFT]: {
    name: 'Redshift',
    connectorType: DataConnectorTypes.REDSHIFT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.BIGQUERY]: {
    name: 'BigQuery',
    connectorType: DataConnectorTypes.BIGQUERY,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SNOWFLAKE]: {
    name: 'Snowflake',
    connectorType: DataConnectorTypes.SNOWFLAKE,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.POSTGRESQL]: {
    name: 'PostgreSQL',
    connectorType: DataConnectorTypes.POSTGRESQL,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.POWERBI]: {
    name: 'Power BI',
    connectorType: DataConnectorTypes.POWERBI,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.MSDYNAMICS]: {
    name: 'Microsoft Dynamics',
    connectorType: DataConnectorTypes.MSDYNAMICS,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SQLSERVER]: {
    name: 'SQL Server',
    connectorType: DataConnectorTypes.SQLSERVER,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.MYSQL]: {
    name: 'MySQL',
    connectorType: DataConnectorTypes.MYSQL,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.ZOHO]: {
    name: 'Zoho',
    connectorType: DataConnectorTypes.ZOHO,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.ADPWORKFORCENOW]: {
    name: 'ADP Workforce Now',
    connectorType: DataConnectorTypes.ADPWORKFORCENOW,
    category: DataConnectorCategories.USER,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.BAMBOOHR]: {
    name: 'BambooHR',
    connectorType: DataConnectorTypes.BAMBOOHR,
    category: DataConnectorCategories.USER,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SAGEHR]: {
    name: 'Sage HR',
    connectorType: DataConnectorTypes.SAGEHR,
    category: DataConnectorCategories.USER,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SAPSUCCESSFACTORS]: {
    name: 'SAP SuccessFactors',
    connectorType: DataConnectorTypes.SAPSUCCESSFACTORS,
    category: DataConnectorCategories.USER,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.WORKDAY]: {
    name: 'Workday',
    connectorType: DataConnectorTypes.WORKDAY,
    category: DataConnectorCategories.USER,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.PERSONIO]: {
    name: 'Personio',
    connectorType: DataConnectorTypes.PERSONIO,
    category: DataConnectorCategories.USER,
    maxFilters: Infinity,
  },
};

export function getDataConnectorName(dataConnector?: null): null;
export function getDataConnectorName(dataConnector: Pick<ConnectorResponse, 'alias' | 'type'>): string;
export function getDataConnectorName(dataConnector?: Pick<ConnectorResponse, 'alias' | 'type'> | null): string | null;
export function getDataConnectorName(dataConnector?: Pick<ConnectorResponse, 'alias' | 'type'> | null): string | null {
  return !dataConnector
    ? null
    : dataConnector.alias
      ? `${CONNECTORS[dataConnector.type].name} ${dataConnector.alias}`
      : CONNECTORS[dataConnector.type].name;
}

export const getConnectorTypeLabel = (customObjectDefinition: Pick<BaseCustomObjectDefinition, 'type'>) => {
  const dataConnectorType = CustomObjectDefToDataConnectorType[customObjectDefinition.type];
  const connector = dataConnectorType && CONNECTORS[dataConnectorType];
  return connector?.name || 'Amalia';
};
