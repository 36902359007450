import { type ForwardedRef, forwardRef, type ReactElement } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { type SelectOptionGroup } from '../../../overlays/select-dropdown/SelectDropdown.types';
import { Select, type SelectProps } from '../Select';
import { type SelectOption } from '../Select.types';

export type FormikSelectProps<
  TOption extends SelectOption = SelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = FormikFieldProps<SelectProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>>;

const FormikSelectForwardRef = forwardRef(function FormikSelect<
  TOption extends SelectOption = SelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>(
  {
    validate, // Omit validate and any props not passed to Select.
    ...props
  }: FormikSelectProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const formikFieldProps = useFormikFieldAdapter<
    SelectProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>['value']
  >({ ...props, validate });

  return (
    <Select<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup>
      {...props}
      {...formikFieldProps}
      ref={ref}
    />
  );
});

export const FormikSelect = Object.assign(
  FormikSelectForwardRef as <
    TOption extends SelectOption = SelectOption,
    TIsMultiple extends boolean | undefined = undefined,
    TUseOptionAsValue extends boolean | undefined = undefined,
    TIsClearable extends boolean | undefined = true,
    TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
  >(
    props: FormikSelectProps<TOption, TIsMultiple, TUseOptionAsValue, TIsClearable, TGroup> & {
      ref?: ForwardedRef<HTMLInputElement>;
    },
  ) => ReactElement | null,
  {
    Size: Select.Size,
  },
);
