import { createContext, useContext } from 'react';

export type FormContextValue = {
  disabled?: boolean;
};

export const FormContext = createContext<FormContextValue>({
  disabled: false,
});

export const useFormContext = () => useContext(FormContext);
