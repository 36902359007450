import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { CellDatePicker, type CellDatePickerProps } from '../CellDatePicker';

export type FormikCellDatePickerProps<TWithRange extends boolean | undefined = undefined> = FormikFieldProps<
  Omit<CellDatePickerProps<TWithRange, undefined>, 'row'>
>;

export const FormikCellDatePicker = function FormikCellDatePicker<TWithRange extends boolean | undefined = undefined>({
  validate,
  ...props
}: FormikCellDatePickerProps<TWithRange>) {
  const { ...formikFieldProps } = useFormikFieldAdapter<CellDatePickerProps<TWithRange, undefined>['value']>({
    ...props,
    validate,
  });

  return (
    <CellDatePicker
      {...props}
      {...formikFieldProps}
      row={undefined}
    />
  );
};
