import { memo } from 'react';

import { TextOverflow, Typography } from '@amalia/design-system/components';
import { CURRENCY_SYMBOL_LABELS, type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { assert } from '@amalia/ext/typescript';

export type CurrencyLabelProps = {
  readonly currencySymbol: CurrencySymbolsEnum;
};

export const CurrencyLabel = memo(function CurrencyLabel({ currencySymbol }: CurrencyLabelProps) {
  assert(currencySymbol in CURRENCY_SYMBOL_LABELS, `Invalid symbol: ${currencySymbol}`);

  return (
    <Typography
      as={TextOverflow}
      variant={Typography.Variant.BODY_BASE_REGULAR}
    >
      {CURRENCY_SYMBOL_LABELS[currencySymbol]}
    </Typography>
  );
});
