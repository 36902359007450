import { css, useTheme } from '@emotion/react';
import { memo } from 'react';

export type CircularProgressProps = {
  /** Circle height/width in px. */
  readonly size: number;
  /** Stroke width in px */
  readonly thickness?: number;
  /** Empty background color. */
  readonly backgroundColor?: string;
  /** Progress background color. */
  readonly progressColor?: string;
  /** Between 0 and 1. */
  readonly progress?: number;
};

export const CircularProgress = memo(function CircularProgress({
  size,
  thickness = 2,
  backgroundColor,
  progressColor,
  progress = 0,
}: CircularProgressProps) {
  const theme = useTheme();

  const radius = (size - thickness) / 2;
  const normalizedProgress = Math.max(0, Math.min(progress, 1));

  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - normalizedProgress * circumference;

  return (
    <svg
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
    >
      <g transform={`rotate(90 ${size / 2} ${size / 2})`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          r={radius}
          stroke={backgroundColor ?? theme.ds.colors.gray[50]}
          strokeWidth={thickness}
        />

        <circle
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          r={radius}
          stroke={progressColor ?? theme.ds.colors.primary[400]}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          strokeWidth={thickness}
          css={(theme) => css`
            transition: ${theme.ds.transitions.default('stroke-dashoffset')};
          `}
        />
      </g>
    </svg>
  );
});
