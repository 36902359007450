import { type VariableDefinition } from '@amalia/amalia-lang/tokens/types';
import { type BaseCustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import {
  type ComputedRule,
  type ComputedVariable,
  type Dataset,
  type DatasetRowContent,
  type DatasetType,
} from '@amalia/payout-calculation/types';
import {
  type PlanAssignment,
  type Filter,
  type PlanResponseDto,
  type Relationship,
} from '@amalia/payout-definition/plans/types';
import { type TeamAssignment } from '@amalia/tenants/assignments/teams/types';
import { type TeamContract } from '@amalia/tenants/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { type CustomObject } from './customObject';
import { type Overwrite } from './overwrite';

// ========== DATASETS ==========

export interface DatasetRow
  extends Pick<CustomObject, 'companyId' | 'createdAt' | 'definitionId' | 'externalId' | 'name' | 'updatedAt' | 'url'> {
  id: string;
  content: DatasetRowContent;
  overwrites?: Overwrite[];
  currency?: CurrencySymbolsEnum;
  isAdditionalRow?: boolean;
}

export type DatasetRowWithAchieved = DatasetRow & { achievedRow: DatasetRow };

export enum DatasetMetricTypes {
  STATEMENT_METRIC = 'statementMetric',
  OBJECT_METRIC = 'objectMetric',
}

export interface StatementDataset {
  id?: string;
  datasetId: string;
  datasetType: DatasetType;
  rows: DatasetRow[];
  customObjectDefinitionMachineName: string;
  fromCache?: boolean;
}

// ========== FULL STATEMENT RESULT ==========

export interface ComputedStatementDefinitions {
  plan: PlanResponseDto;

  // Indexed by machineName.
  variables: Record<string, VariableDefinition>;

  // Indexed by machineName.
  relationships: Record<string, Relationship>;

  // Indexed by machineName.
  customObjects: Record<string, BaseCustomObjectDefinition>;

  // Indexed by machineName.
  filters: Record<
    string,
    Pick<Filter, 'condition' | 'formulaBuilder' | 'id' | 'machineName' | 'name' | 'orderBy' | 'uniqueBy'> & {
      customObjectDefinitionMachineName: string;
    }
  >;
}

export interface ComputedTeam {
  isTeamArchived: TeamContract['archived'];
  teamId: TeamContract['id'];
  name: TeamContract['name'];
  teamAssignmentId: TeamAssignment['id'];
  teamRole: TeamAssignment['teamRole'];
  effectiveAsOf: TeamAssignment['effectiveAsOf'];
  effectiveUntil: TeamAssignment['effectiveUntil'];
}

interface ComputedStatementPlanAssignment {
  user: UserContract;
  id: PlanAssignment['id'];
  effectiveAsOf: PlanAssignment['effectiveAsOf'];
  effectiveUntil: PlanAssignment['effectiveUntil'];
  mainTeamId: PlanAssignment['mainTeamId'];
  plan: {
    id: string;
    name: string;
  };
}

export interface ComputedStatement {
  definitions: ComputedStatementDefinitions;

  planAssignment: ComputedStatementPlanAssignment;

  datasets: Dataset[];

  computedObjects: (ComputedRule | ComputedVariable)[];

  computedTeams?: ComputedTeam[];

  teamUsedDuringCalculationId: string | null;

  rates: Partial<Record<CurrencySymbolsEnum, number>>;
}

export type ComputedStatementSummary = Pick<ComputedStatement, 'computedObjects' | 'computedTeams'> & {
  definitions: Partial<ComputedStatementDefinitions>;
  planAssignment: Pick<ComputedStatementPlanAssignment, 'mainTeamId'>;
};

export type ComputedVariableWithVariableIdAndLabel = ComputedVariable & {
  kpiVariableId: string;
  label: string;
  achievedValue?: number | null;
};
