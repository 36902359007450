import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { CellQuickSwitch, type CellQuickSwitchProps } from '../CellQuickSwitch';
import { type CellQuickSwitchOption } from '../CellQuickSwitch.types';

export type FormikCellQuickSwitchProps<TOption extends CellQuickSwitchOption = CellQuickSwitchOption> =
  FormikFieldProps<Omit<CellQuickSwitchProps<TOption, undefined>, 'row'>>;

export const FormikCellQuickSwitch = function FormikCellQuickSwitch<
  TOption extends CellQuickSwitchOption = CellQuickSwitchOption,
>({ validate, ...props }: FormikCellQuickSwitchProps<TOption>) {
  const { ...formikFieldProps } = useFormikFieldAdapter<CellQuickSwitchProps<TOption, undefined>['value']>({
    ...props,
    validate,
  });

  return (
    <CellQuickSwitch
      {...props}
      {...formikFieldProps}
      row={undefined}
    />
  );
};
