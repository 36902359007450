import styled from '@emotion/styled';

import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';

export const DropdownItemSecondaryLabel = styled(TextOverflow)`
  ${({ theme }) => theme.ds.typographies.bodyXsmallRegular};

  max-width: 40%;
  font-style: italic;
  color: ${({ theme }) => theme.ds.colors.gray[600]};

  transition: ${({ theme }) => theme.ds.transitions.default('color')};

  padding-right: 1px; // text-overflow cuts italic letters, this is a workaround.

  &[data-disabled='true'] {
    color: inherit;
  }
`;
