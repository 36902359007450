import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';

import { type VariablesMap, type Variable, VariableType } from '@amalia/amalia-lang/tokens/types';
import { useSnackbars } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';
import { type Plan } from '@amalia/payout-definition/plans/types';

import { calculationScopesQueryKeys } from '../calculation-scope/calculation-scope.keys';
import { plansForecastQueryKeys } from '../plan-forecast/plan-forecast.keys';
import { plansQueryKeys, rulesV2QueryKeys } from '../queries.keys';

import { VariablesApiClient } from './variables.api-client';
import { variablesMutationKeys, variablesQueryKeys } from './variables.keys';

export const useFieldsListByObjectId = (customDefinitionObjectId?: string) =>
  useQuery({
    queryKey: variablesQueryKeys.listByCustomObjectDefinition(customDefinitionObjectId!),
    queryFn: () => {
      assert(customDefinitionObjectId, 'customDefinitionObjectId is required');
      return VariablesApiClient.getByObjectId(customDefinitionObjectId);
    },
    placeholderData: [],
    enabled: !!customDefinitionObjectId,
  });

export const useVariables = ({
  filters,
  select,
}: {
  filters?: { types?: VariableType[]; userIds?: string[]; planIds?: string[] };
  select?: (variables: Variable[]) => Variable[];
} = {}) =>
  useQuery({
    queryKey: variablesQueryKeys.list(filters),
    queryFn: () => VariablesApiClient.list(filters?.types, filters?.userIds, filters?.planIds),
    select,
  });

export const useVariablesMap = (...args: Parameters<typeof useVariables>) => {
  const { data, ...rest } = useVariables(...args);
  return { data: data ? (keyBy(data, 'id') satisfies VariablesMap as VariablesMap) : undefined, ...rest };
};

/**
 * @deprecated This hook is deprecated and will be removed when removing dv2 => useImportTokenFromOtherPlan().
 */
export const useDuplicateVariablesInNewContext = () => {
  const queryClient = useQueryClient();
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: (args: Parameters<typeof VariablesApiClient.duplicateInNewContext>) =>
      VariablesApiClient.duplicateInNewContext(...args),

    onSuccess: async ({ isErrorWhileDuplicating }) => {
      if (isErrorWhileDuplicating) {
        snackError(
          formatMessage({
            defaultMessage: 'Error while importing variables. Variables with the same name already exist.',
          }),
        );
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: variablesQueryKeys.allLists() }),
        queryClient.invalidateQueries({ queryKey: calculationScopesQueryKeys.all() }),
      ]);
    },
  });
};

const patchVariableSuccessMessages = defineMessages<VariableType>({
  [VariableType.object]: { defaultMessage: 'Calculated column updated.' },
  [VariableType.statement]: { defaultMessage: 'Variable updated.' },
  [VariableType.user]: { defaultMessage: 'User quota definition updated.' },
  [VariableType.team]: { defaultMessage: 'Team quota definition updated.' },
  [VariableType.plan]: { defaultMessage: 'Plan quota definition updated.' },
});

export const usePatchVariable = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: variablesMutationKeys.patch(),
    mutationFn: VariablesApiClient.patch,
    onSuccess: async (updatedVariable) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: calculationScopesQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: variablesQueryKeys.all() }),
        queryClient.invalidateQueries({
          queryKey: updatedVariable.planId
            ? plansQueryKeys.template.ofPlan(updatedVariable.planId)
            : plansQueryKeys.template.all(),
        }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.all() }),
      ]);
      snackSuccess(formatMessage(patchVariableSuccessMessages[updatedVariable.type]));
    },
    onError: (error) => {
      snackError(formatMessage({ defaultMessage: `Error while updating: {message}` }, { message: error.message }));
    },
  });
};

const createVariableSuccessMessages = defineMessages<VariableType>({
  [VariableType.object]: { defaultMessage: 'Calculated column created.' },
  [VariableType.statement]: { defaultMessage: 'Variable created.' },
  [VariableType.user]: { defaultMessage: 'User quota created.' },
  [VariableType.team]: { defaultMessage: 'Team quota created.' },
  [VariableType.plan]: { defaultMessage: 'Plan quota created.' },
});

const createVariableErrorMessages = defineMessages<VariableType>({
  [VariableType.object]: { defaultMessage: 'Error while creating calculated column: {errorMessage}' },
  [VariableType.statement]: { defaultMessage: 'Error while creating variable: {errorMessage}' },
  [VariableType.user]: { defaultMessage: 'Error while creating user quota: {errorMessage}' },
  [VariableType.team]: { defaultMessage: 'Error while creating team quota: {errorMessage}' },
  [VariableType.plan]: { defaultMessage: 'Error while creating plan quota: {errorMessage}' },
});

export const useCreateVariable = ({ planId, ruleId }: { planId: string; ruleId: string }) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: variablesMutationKeys.create(),
    mutationFn: VariablesApiClient.create,
    onSuccess: async (updatedVariable) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: calculationScopesQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: variablesQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.details(planId, ruleId) }),
        queryClient.invalidateQueries({
          queryKey: updatedVariable.planId
            ? plansQueryKeys.template.ofPlan(updatedVariable.planId)
            : plansQueryKeys.template.all(),
        }),
        queryClient.invalidateQueries({ queryKey: plansForecastQueryKeys.details(planId) }),
      ]);
      snackSuccess(formatMessage(createVariableSuccessMessages[updatedVariable.type]));
    },
    onError: (error, { variable: { type } }) => {
      snackError(formatMessage(createVariableErrorMessages[type], { errorMessage: error.message }));
    },
  });
};

const deleteVariableSuccessMessages = defineMessages<VariableType>({
  [VariableType.object]: { defaultMessage: 'Calculated column deleted successfully.' },
  [VariableType.statement]: { defaultMessage: 'Variable deleted successfully.' },
  [VariableType.user]: { defaultMessage: 'User quota deleted successfully.' },
  [VariableType.team]: { defaultMessage: 'Team quota deleted successfully.' },
  [VariableType.plan]: { defaultMessage: 'Plan quota deleted successfully.' },
});

export const useDeleteVariable = ({
  planId,
  onErrorOverride,
}: {
  planId: Plan['id'];
  onErrorOverride?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: variablesMutationKeys.delete(),
    mutationFn: (variableId: Variable['id']) => VariablesApiClient.delete(variableId, planId),
    onSuccess: async (deletedVariable) => {
      snackSuccess(formatMessage(deleteVariableSuccessMessages[deletedVariable.type]));
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: calculationScopesQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: rulesV2QueryKeys.configurations.ofPlan.all(planId) }),
        queryClient.invalidateQueries({ queryKey: variablesQueryKeys.all() }),
        queryClient.invalidateQueries({ queryKey: plansQueryKeys.template.all() }),
        queryClient.invalidateQueries({ queryKey: plansForecastQueryKeys.details(planId) }),
      ]);
    },
    onError: (err) => {
      if (onErrorOverride) {
        onErrorOverride(err);
      } else {
        snackError(
          formatMessage(
            { defaultMessage: 'Error while deleting token: {errorMessage}.' },
            { errorMessage: err.message },
          ),
        );
      }
    },
  });
};
