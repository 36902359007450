import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { type AssignmentDates } from '@amalia/assignments/common/shared';
import { Table, useDatePickerTimestampsAdapter, type CellDatePickerProps } from '@amalia/design-system/components';
import { type UnixTimestampInSeconds } from '@amalia/ext/dates';
import { dayjs } from '@amalia/ext/dayjs';
import { type MergeAll } from '@amalia/ext/typescript';

export type AssignmentDateCellProps<TAssignment extends AssignmentDates> = MergeAll<
  [
    Omit<CellDatePickerProps<false, TAssignment>, 'dateFormat' | 'placeholder'>,
    {
      readonly value: UnixTimestampInSeconds | null;
      readonly boundary: 'end' | 'start';
      readonly maxDate?: UnixTimestampInSeconds | null;
      readonly minDate?: UnixTimestampInSeconds | null;
      readonly onChange: (value: UnixTimestampInSeconds | null, assignment: TAssignment) => void;
      readonly isReadonly?: boolean;
    },
  ]
>;

export const AssignmentDateCell = function AssignmentDateCell<TAssignment extends AssignmentDates>({
  value,
  boundary,
  minDate,
  maxDate,
  onChange,
  row,
  isReadonly = false,
  ...props
}: AssignmentDateCellProps<TAssignment>) {
  const { formatMessage } = useIntl();

  const datePickerProps = useDatePickerTimestampsAdapter({
    value,
    minDate,
    maxDate,
    boundary,
    onChange: useCallback((value: UnixTimestampInSeconds | null) => onChange(value, row), [onChange, row]),
  });

  const placeholder = (
    <span
      css={(theme) => css`
        color: ${theme.ds.colors.gray[700]};
      `}
    >
      {formatMessage({ defaultMessage: 'Indefinite' })}
    </span>
  );

  return isReadonly ? (
    <Table.Cell.Text>{value ? dayjs.utc(value, 'X').format('YYYY-MM-DD') : placeholder}</Table.Cell.Text>
  ) : (
    <Table.Cell.DatePicker<false, TAssignment>
      {...props}
      {...datePickerProps}
      clearButtonLabel={formatMessage({ defaultMessage: 'Reset date to indefinite' })}
      dateFormat="yyyy-MM-dd"
      placeholder={placeholder}
      row={row}
    />
  );
};
