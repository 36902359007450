import { css } from '@emotion/react';
import { isNil, last } from 'lodash';
import {
  cloneElement,
  type JSXElementConstructor,
  memo,
  type ReactElement,
  type CSSProperties,
  type ReactNode,
} from 'react';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';

import { type AlertBannerProps } from '../../feedback/alert-banner/AlertBanner';

import { CellAction } from './cell-helpers/cell-action/CellAction';
import { CellActions } from './cell-helpers/cell-actions/CellActions';
import { CellDatePicker } from './cell-helpers/cell-date-picker/CellDatePicker';
import { FormikCellDatePicker } from './cell-helpers/cell-date-picker/formik-cell-date-picker/FormikCellDatePicker';
import { CellIconAction } from './cell-helpers/cell-icon-action/CellIconAction';
import { CellLoading } from './cell-helpers/cell-loading/CellLoading';
import { CellMain } from './cell-helpers/cell-main/CellMain';
import { CellNoValue } from './cell-helpers/cell-no-value/CellNoValue';
import { CellQuickSwitch } from './cell-helpers/cell-quick-switch/CellQuickSwitch';
import { FormikCellQuickSwitch } from './cell-helpers/cell-quick-switch/formik-cell-quick-switch/FormikCellQuickSwitch';
import { CellSelect } from './cell-helpers/cell-select/CellSelect';
import { FormikCellSelect } from './cell-helpers/cell-select/formik-cell-select/FormikCellSelect';
import { CellText } from './cell-helpers/cell-text/CellText';
import { CellTextField } from './cell-helpers/cell-text-field/CellTextField';
import { FormikCellTextField } from './cell-helpers/cell-text-field/formik-cell-text-field/FormikCellTextField';
import { CellWithActions } from './cell-helpers/cell-with-actions/CellWithActions';
import { ColumnAction } from './column-helpers/column-action/ColumnAction';
import { ColumnActions } from './column-helpers/column-actions/ColumnActions';
import { ColumnLinkTooltip } from './column-helpers/column-link-tooltip/ColumnLinkTooltip';
import { ColumnTooltip } from './column-helpers/column-tooltip/ColumnTooltip';
import { useTableMultiSelection } from './hooks/useTableMultiSelection';
import { useTableScroll } from './hooks/useTableScroll';
import { TableBody } from './layout/table-body/TableBody';
import { TableBodyLoading } from './layout/table-body-loading/TableBodyLoading';
import { TableDataCell } from './layout/table-data-cell/TableDataCell';
import { TableDataCellContent } from './layout/table-data-cell-content/TableDataCellContent';
import { shouldRenderHeader } from './layout/table-header/should-render-header';
import { TableHeader } from './layout/table-header/TableHeader';
import { TableRow } from './layout/table-row/TableRow';
import { TableBulkAction } from './table-helpers/bulk-actions/bulk-action/TableBulkAction';
import { TableBulkActions, type TableBulkActionsProps } from './table-helpers/bulk-actions/TableBulkActions';
import { TableContext, type TableContextValue } from './Table.context';
import * as styles from './Table.styles';
import { type ColumnDefinitions, type RowData, type RowKey } from './Table.types';

// This function calculates the number of data rows in the table based on various conditions.
// It takes into account whether there is an alert, whether the table is loading, whether there is a placeholder,
// and the length of the data array.
const getDataRowsCount = ({
  hasAlert,
  isLoading,
  loadingRowsCount,
  hasPlaceHolder,
  data,
}: {
  hasAlert: boolean;
  isLoading: boolean;
  loadingRowsCount: number;
  hasPlaceHolder: boolean;
  data: unknown[];
}) => {
  switch (true) {
    case isLoading:
      return loadingRowsCount;
    case hasAlert:
      return 1;
    case hasPlaceHolder && !data.length:
      return 1;
    default:
      return data.length;
  }
};

export type TableProps<TData extends RowData, TKey extends RowKey> = {
  /** Column definitions. */
  readonly columns: ColumnDefinitions<TData>;
  /** Rows. */
  readonly data?: TData[];
  /** Get row key. */
  readonly rowKey: TableContextValue<TData, TKey>['rowKey'];
  /** HTML id attribute set on the `<table>` element. */
  readonly id?: string;
  /** Selected row ids. */
  readonly selectedRows?: TKey[];
  /** Callback when a row is selected. */
  readonly onChangeSelectedRows?: (selectedRows: TKey[]) => void;
  /** Show an AlertBanner instead of data, in case of error/warning/no data. */
  readonly alert?: ReactElement<AlertBannerProps, JSXElementConstructor<AlertBannerProps>>;
  /** Ignore data, show Skeleton in every cell. */
  readonly isLoading?: boolean;
  /** Ignore data, show Skeleton in all data cells (will keep header as-is). */
  readonly isDataLoading?: boolean;
  /** When loading, how many rows to display. */
  readonly loadingRowsCount?: number;
  /** Should make the first column sticky. */
  readonly pinFirstColumn?: boolean;
  /** Should make the last column sticky (mainly for actions). */
  readonly pinLastColumn?: boolean;
  /** Disable row selection if returns false. */
  readonly isRowSelectableFn?: (row: TData) => boolean;
  /** Bulk actions. Only works if selectedRows is defined. */
  readonly bulkActions?: ReactElement<TableBulkActionsProps, JSXElementConstructor<TableBulkActionsProps>>;
  /** Override the background color of a specific row. */
  readonly getRowBackgroundColor?: (row: TData) => CSSProperties['backgroundColor'];
  /** Vertical align rows content. */
  readonly verticalAlign?: CSSProperties['verticalAlign'];
  /** Placeholder */
  readonly placeholder?: ReactNode;
};

const DEFAULT_DATA: never[] = [];

const TableBase = function Table<TData extends RowData, TKey extends RowKey>({
  data = DEFAULT_DATA as TData[],
  columns,
  rowKey,
  id = undefined,
  alert = undefined,
  isLoading = false,
  isDataLoading = false,
  loadingRowsCount = 10,
  pinFirstColumn = false,
  pinLastColumn = last(columns)?.id === CellActions.columnId,
  selectedRows = undefined,
  onChangeSelectedRows = undefined,
  isRowSelectableFn,
  bulkActions,
  getRowBackgroundColor,
  verticalAlign = 'middle',
  placeholder,
}: TableProps<TData, TKey>) {
  const {
    isSelectionEnabled,
    isSelectAllDisabled,
    selectedRowsCount,
    selectionColumnRef,
    selectionColumnWidth,
    handleSelectAllRows,
    handleSelectRow,
    hasSomeRowsSelected,
    hasAllRowsSelected,
  } = useTableMultiSelection({
    data,
    rowKey,
    selectedRows,
    onChangeSelectedRows,
    isRowSelectableFn,
    enabled: !alert && !isNil(selectedRows),
  });

  const dataRowsCount = getDataRowsCount({
    hasAlert: !!alert,
    isLoading: isLoading || isDataLoading,
    loadingRowsCount,
    hasPlaceHolder: !!placeholder,
    data,
  });

  const isHeaderRendered = shouldRenderHeader<TData>(columns, isSelectionEnabled);

  const headerRowsCount = isHeaderRendered ? 1 : 0;

  const { containerRef, onContainerScroll, isFullyScrolledToTheLeft, isFullyScrolledToTheRight } = useTableScroll();

  const contextValue = useShallowObjectMemo<TableContextValue<TData, TKey>>({
    dataRowsCount,
    headerRowsCount,
    selectedRowsCount,
    totalRowsCount: dataRowsCount + headerRowsCount,
    isLoading,
    pinFirstColumn,
    pinLastColumn,
    rowKey,
  });

  return (
    // Need to cast because we can't use generics in contexts.
    <TableContext.Provider value={contextValue as unknown as TableContextValue<RowData, RowKey>}>
      <div
        ref={containerRef}
        css={styles.tableContainer}
        data-scrolled-to-the-left={isFullyScrolledToTheLeft}
        data-scrolled-to-the-right={isFullyScrolledToTheRight}
        onScroll={onContainerScroll}
      >
        <table
          id={id ? `${id}-table` : undefined}
          css={[
            styles.table,
            css`
              td {
                vertical-align: ${verticalAlign};
              }
            `,
          ]}
        >
          {!!isHeaderRendered && (
            <TableHeader<TData, TKey>
              bulkActions={bulkActions}
              columns={columns}
              handleSelectAllRows={handleSelectAllRows}
              hasAllRowsSelected={hasAllRowsSelected}
              hasSomeRowsSelected={hasSomeRowsSelected}
              isSelectAllDisabled={isSelectAllDisabled}
              isSelectionEnabled={isSelectionEnabled}
              selectedRowsCount={selectedRowsCount}
              selectionColumnRef={selectionColumnRef}
              selectionColumnWidth={selectionColumnWidth}
            />
          )}
          {(() => {
            switch (true) {
              case isLoading || isDataLoading:
                return (
                  <TableBodyLoading
                    columns={columns}
                    rowsCount={loadingRowsCount}
                  />
                );
              case !!alert:
                return (
                  <tbody>
                    <TableRow index={headerRowsCount}>
                      <TableDataCell
                        colSpan={Math.max(1, columns.length)} // Span all columns.
                        css={styles.alertCell}
                      >
                        {cloneElement(alert, { inline: true })}
                      </TableDataCell>
                    </TableRow>
                  </tbody>
                );
              case placeholder && !data.length:
                return (
                  <tbody>
                    <TableRow index={headerRowsCount}>
                      <TableDataCell colSpan={Math.max(1, columns.length)}>{placeholder}</TableDataCell>
                    </TableRow>
                  </tbody>
                );
              default:
                return (
                  <TableBody<TData, TKey>
                    columns={columns}
                    data={data}
                    getRowBackgroundColor={getRowBackgroundColor}
                    isRowSelectableFn={isRowSelectableFn}
                    isSelectionEnabled={isSelectionEnabled}
                    selectedRows={selectedRows}
                    selectionColumnWidth={selectionColumnWidth}
                    onSelectRow={handleSelectRow}
                  />
                );
            }
          })()}
        </table>
      </div>
    </TableContext.Provider>
  );
};

export const Table = Object.assign(memo(TableBase) as typeof TableBase, {
  Cell: Object.assign(TableDataCellContent, {
    Actions: CellActions,
    Action: CellAction,
    IconAction: CellIconAction,
    DatePicker: CellDatePicker,
    FormikDatePicker: FormikCellDatePicker,
    Main: CellMain,
    QuickSwitch: CellQuickSwitch,
    FormikQuickSwitch: FormikCellQuickSwitch,
    NoValue: CellNoValue,
    Loading: CellLoading,
    Select: CellSelect,
    FormikSelect: FormikCellSelect,
    Text: CellText,
    TextField: CellTextField,
    FormikTextField: FormikCellTextField,
    WithActions: CellWithActions,
  }),
  Column: {
    Actions: ColumnActions,
    Action: ColumnAction,
    Tooltip: ColumnTooltip,
    LinkTooltip: ColumnLinkTooltip,
  },
  BulkActions: TableBulkActions,
  BulkAction: TableBulkAction,
});
