import clsx from 'clsx';
import { noop } from 'lodash';
import {
  type ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
} from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { useFormLayoutContext } from '../../layout/form-layout/FormLayout.context';
import { FormLayoutSize } from '../../layout/form-layout/FormLayout.types';
import { useFormContext } from '../meta/form-context';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';
import { FieldSize } from '../meta/types';

import * as styles from './TextArea.styles';
import { TextAreaSize } from './TextArea.types';

const TEXTAREA_SIZE_FIELD_SIZE_MAPPING: Record<TextAreaSize, FieldSize> = {
  [TextAreaSize.SMALL]: FieldSize.SMALL,
  [TextAreaSize.MEDIUM]: FieldSize.MEDIUM,
};

const FORM_LAYOUT_SIZE_TEXTAREA_SIZE_MAPPING: Record<FormLayoutSize, TextAreaSize> = {
  [FormLayoutSize.SMALL]: TextAreaSize.SMALL,
  [FormLayoutSize.MEDIUM]: TextAreaSize.MEDIUM,
};

export type TextAreaProps = MergeAll<
  [
    ComponentPropsWithoutRef<'textarea'>,
    UseFormFieldPropsOptions,
    {
      /** Textarea size. */
      size?: TextAreaSize;
      /** Textarea value. Leave undefined for uncontrolled textarea. */
      value?: string;
      /** Change handler. */
      onChange?: (value: string) => void;
    },
  ]
>;

const TextAreaForwardRef = forwardRef(function TextArea(props: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) {
  const { size: formLayoutSize } = useFormLayoutContext() || {};
  const { disabled: formContextDisabled } = useFormContext();

  const {
    formFieldProps,
    otherProps: {
      className,
      size = formLayoutSize ? FORM_LAYOUT_SIZE_TEXTAREA_SIZE_MAPPING[formLayoutSize] : TextAreaSize.MEDIUM,
      onChange = noop,
      ...otherProps
    },
  } = useFormFieldProps({ ...props, disabled: props.disabled || formContextDisabled });

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => onChange(event.currentTarget.value),
    [onChange],
  );

  return (
    <FormField
      {...formFieldProps}
      size={TEXTAREA_SIZE_FIELD_SIZE_MAPPING[size]}
    >
      <textarea
        {...otherProps}
        ref={ref}
        css={styles.textarea}
        className={clsx(className, size, {
          [styles.HAS_ERROR_CLASSNAME]: !!formFieldProps.error,
        })}
        onChange={handleChange}
      />
    </FormField>
  );
});

export const TextArea = Object.assign(memo(TextAreaForwardRef), {
  Size: TextAreaSize,
});
