import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';

import { type FilterOrderBy } from './filters';

export interface Relationship {
  id: string;

  label: string;

  name: string;

  fromDefinitionMachineName: string;

  fromDefinitionField?: string | null;

  toDefinitionMachineName: string;

  toDefinitionField?: string | null;

  condition?: AmaliaFormula | null;

  type: RelationshipType;

  // INNER or LEFT join.
  // Note: inner join is not supported for 1->N joins.
  joinType?: RelationshipJoinType | null;

  // Allows sorting of linked records. For 1-1 links it's really useful because if multiple
  // elements answer the condition, we'll take the first one, so it needs to be deterministic.
  orderBy: FilterOrderBy[] | null;
}

export type RelationshipRequest = Omit<Relationship, 'id' | 'name'>;

export enum RelationshipType {
  ARRAY = 'array',
  OBJECT = 'object',
}

export enum RelationshipJoinType {
  INNER = 'inner',
  LEFT = 'left',
}
