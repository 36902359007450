import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { type RadioOptionValue } from '../../radio/Radio.types';
import { RadioButtonGroup, type RadioButtonGroupProps } from '../RadioButtonGroup';

export type FormikRadioButtonGroupProps<TValue extends RadioOptionValue> = FormikFieldProps<
  RadioButtonGroupProps<TValue>
>;

const FormikRadioButtonGroupBase = function FormikRadioButtonGroup<TValue extends RadioOptionValue>({
  validate, // Omit validate and any props not passed to RadioButtonGroup.
  ...props
}: FormikRadioButtonGroupProps<TValue>) {
  const formikFieldProps = useFormikFieldAdapter<RadioButtonGroupProps<TValue>['value']>({
    ...props,
    validate,
  });

  return (
    <RadioButtonGroup
      {...props}
      {...formikFieldProps}
    />
  );
};

export const FormikRadioButtonGroup = Object.assign(FormikRadioButtonGroupBase, {
  Size: RadioButtonGroup.Size,
});
