import { type DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import {
  CustomObjectDefinitionType,
  CustomObjectDefToDataConnectorType,
} from '@amalia/data-capture/record-models/types';
import { isEnum } from '@amalia/ext/typescript';
import { IconAmaliaLogo } from '@amalia/frontend/ui-icons';

import { VENDOR_LOGOS } from './connectors.logos';

export const getVendorLogo = (objectDefinitionType?: CustomObjectDefinitionType | DataConnectorTypes | null) => {
  if (!objectDefinitionType) {
    return IconAmaliaLogo;
  }
  const dataConnectorType = isEnum(objectDefinitionType, CustomObjectDefinitionType)
    ? CustomObjectDefToDataConnectorType[objectDefinitionType]
    : objectDefinitionType;

  return dataConnectorType ? VENDOR_LOGOS[dataConnectorType].CompactLogo : IconAmaliaLogo;
};
