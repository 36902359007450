import { memo } from 'react';
import { useIntl } from 'react-intl';

import { Table } from '@amalia/design-system/components';
import { intlConfig } from '@amalia/kernel/intl/components';

const languageOptions = intlConfig.locales.map((locale) => ({
  value: locale,
  label: intlConfig.localeLabels[locale],
}));

export const LanguageSelectCell = memo(function LanguageSelectCell() {
  const { formatMessage } = useIntl();

  return (
    <Table.Cell.FormikSelect
      data-testid="user-info-table-select-language"
      isClearable={false}
      name="language"
      options={languageOptions}
      placeholder={formatMessage({
        defaultMessage: 'Language',
      })}
    />
  );
});
