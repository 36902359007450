import { memo } from 'react';

import { Group } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import { CurrencyLabel } from './CurrencyLabel';
import { CurrencySymbol } from './CurrencySymbol';

export type CurrencyDisplayProps = {
  readonly currencySymbol: CurrencySymbolsEnum;
};

export const CurrencyDisplay = memo(function CurrencyDisplay({ currencySymbol }: CurrencyDisplayProps) {
  return (
    <Group
      align="center"
      gap={6}
    >
      <CurrencySymbol currencySymbol={currencySymbol} />
      <CurrencyLabel currencySymbol={currencySymbol} />
    </Group>
  );
});
