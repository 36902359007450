import { noop } from 'lodash';
import {
  type ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
} from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { useFormLayoutContext } from '../../layout/form-layout/FormLayout.context';
import { FormLayoutSize } from '../../layout/form-layout/FormLayout.types';
import { useFormContext } from '../meta/form-context';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';
import { FieldSize, LabelPosition } from '../meta/types';

import { switchFormFieldType } from './Switch.constants';
import * as styles from './Switch.styles';
import { SwitchSize } from './Switch.types';

const SWITCH_SIZE_ICON_BUTTON_SIZE_MAPPING: Record<SwitchSize, FieldSize> = {
  [SwitchSize.SMALL]: FieldSize.SMALL,
  [SwitchSize.MEDIUM]: FieldSize.MEDIUM,
  [SwitchSize.LARGE]: FieldSize.LARGE,
};

const FORM_LAYOUT_SIZE_SWITCH_SIZE_MAPPING: Record<FormLayoutSize, SwitchSize> = {
  [FormLayoutSize.SMALL]: SwitchSize.SMALL,
  [FormLayoutSize.MEDIUM]: SwitchSize.MEDIUM,
};

export type SwitchProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'input'>, 'type'>,
    Omit<UseFormFieldPropsOptions, 'labelPosition'>,
    {
      /** Switch size. */
      size?: SwitchSize;
      /** Change handler. Called with the new value. */
      onChange?: (checked: boolean) => void;
    },
  ]
>;

const SwitchForwardRef = forwardRef(function Switch(props: SwitchProps, ref: ForwardedRef<HTMLInputElement>) {
  const { size: formLayoutSize } = useFormLayoutContext() || {};
  const { disabled: formContextDisabled } = useFormContext();

  const {
    formFieldProps,
    otherProps: {
      size = formLayoutSize ? FORM_LAYOUT_SIZE_SWITCH_SIZE_MAPPING[formLayoutSize] : SwitchSize.MEDIUM,
      onChange = noop,
      ...otherProps
    },
  } = useFormFieldProps({ ...props, disabled: props.disabled || formContextDisabled });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => onChange(event.currentTarget.checked),
    [onChange],
  );

  return (
    <FormField
      {...formFieldProps}
      formFieldType={switchFormFieldType}
      labelPosition={LabelPosition.RIGHT}
      size={SWITCH_SIZE_ICON_BUTTON_SIZE_MAPPING[size]}
    >
      <label css={styles.label}>
        <input
          {...otherProps}
          ref={ref}
          css={styles.hidden}
          type="checkbox"
          onChange={handleChange}
        />

        <div
          className={size}
          css={styles.switchControl}
        />
      </label>
    </FormField>
  );
});

export const Switch = Object.assign(memo(SwitchForwardRef), {
  Size: SwitchSize,
});
