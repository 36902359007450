import { useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

import { CustomObjectDefinitionsApiClient } from '../api-client/custom-object-definitions.api-client';

import { customObjectDefinitionsQueryKeys } from './queries.keys';

export const useRealCustomObjectDefinitions = ({ enabled = true }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: customObjectDefinitionsQueryKeys.listReal(),
    queryFn: CustomObjectDefinitionsApiClient.list,
    enabled,
  });

export const useRealCustomObjectDefinition = (machineName: CustomObjectDefinition['machineName'] | null) =>
  useQuery({
    queryKey: customObjectDefinitionsQueryKeys.detailsReal(machineName!),
    queryFn: () => CustomObjectDefinitionsApiClient.getOneReal(machineName!),
    enabled: !!machineName,
  });

export const useVirtualCustomObjectDefinitions = ({ enabled = true }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: customObjectDefinitionsQueryKeys.listVirtual(),
    queryFn: CustomObjectDefinitionsApiClient.listVirtual,
    enabled,
  });

export const useVirtualCustomObjectDefinition = (machineName: string | null) =>
  useQuery({
    queryKey: customObjectDefinitionsQueryKeys.detailsVirtual(machineName!),
    queryFn: () => CustomObjectDefinitionsApiClient.getOneVirtual(machineName!),
    enabled: !!machineName,
  });

export const useCustomObjectDefinitions = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const realResult = useRealCustomObjectDefinitions({ enabled });
  const virtualResult = useVirtualCustomObjectDefinitions({ enabled });

  return useMemo(
    () => ({
      data: [...(realResult.data ?? []), ...(virtualResult.data ?? [])],
      isError: realResult.isError || virtualResult.isError,
      isLoading: realResult.isLoading || virtualResult.isLoading,
      isPending: realResult.isPending || virtualResult.isPending,
    }),
    [realResult, virtualResult],
  );
};

export const useCustomObjectDefinitionsMap = () => {
  const { data: customObjectDefinitions, ...otherProps } = useCustomObjectDefinitions();

  return useMemo(
    () => ({
      data: keyBy(customObjectDefinitions, 'machineName'),
      ...otherProps,
    }),
    [customObjectDefinitions, otherProps],
  );
};

export const useCustomObjectDefinition = (machineName: CustomObjectDefinition['machineName'] | null) => {
  const { data, ...otherProps } = useCustomObjectDefinitionsMap();

  return useMemo(
    () => ({
      data: machineName ? ((data[machineName] as CustomObjectDefinition | undefined) ?? null) : null,
      ...otherProps,
    }),
    [data, machineName, otherProps],
  );
};
