import { memo } from 'react';
import { useIntl } from 'react-intl';

import { Table } from '@amalia/design-system/components';
import { type UserInfo } from '@amalia/tenants/users/profile/types';

import { useCompanyCurrencyOptions } from './use-company-currency-options';

export type CurrencySelectCellProps = {
  readonly row: Pick<UserInfo, 'currency'>;
};

export const CurrencySelectCell = memo(function CurrencySelectCell({ row }: CurrencySelectCellProps) {
  const { formatMessage } = useIntl();
  const value = row.currency;

  const currencyOptions = useCompanyCurrencyOptions({ currentValue: value });

  return (
    <Table.Cell.FormikSelect
      data-testid="user-info-table-select-currency"
      isClearable={false}
      name="currency"
      options={currencyOptions}
      placeholder={formatMessage({
        defaultMessage: 'Currency',
      })}
    />
  );
});
