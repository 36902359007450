import clsx from 'clsx';
import { noop } from 'lodash';
import {
  memo,
  useCallback,
  forwardRef,
  type ForwardedRef,
  type ChangeEventHandler,
  cloneElement,
  type ReactElement,
  type JSXElementConstructor,
  type ComponentPropsWithoutRef,
} from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { type TablerIconElement } from '../../general/icons/types';
import { useFormLayoutContext } from '../../layout/form-layout/FormLayout.context';
import { FormLayoutSize } from '../../layout/form-layout/FormLayout.types';
import { useFormContext } from '../meta/form-context';
import { FormField } from '../meta/form-field/FormField';
import { type UseFormFieldPropsOptions, useFormFieldProps } from '../meta/form-field/hooks/useFormFieldProps';
import { FieldSize } from '../meta/types';

import { InputClearAction } from './input-action/clear/InputClearAction';
import { InputAction, type InputActionProps } from './input-action/InputAction';
import * as styles from './Input.styles';
import { InputSize } from './Input.types';

const INPUT_SIZE_FIELD_SIZE_MAPPING: Record<InputSize, FieldSize> = {
  [InputSize.SMALL]: FieldSize.SMALL,
  [InputSize.MEDIUM]: FieldSize.MEDIUM,
};

const FORM_LAYOUT_SIZE_INPUT_SIZE_MAPPING: Record<FormLayoutSize, InputSize> = {
  [FormLayoutSize.SMALL]: InputSize.SMALL,
  [FormLayoutSize.MEDIUM]: InputSize.MEDIUM,
};

export type InputProps = MergeAll<
  [
    ComponentPropsWithoutRef<'input'>,
    UseFormFieldPropsOptions,
    {
      /** Input size. */
      size?: InputSize;
      /** Input value. Leave undefined for uncontrolled input. */
      value?: string;
      /** Change handler. Called with the new value. */
      onChange?: (value: string) => void;
      /** Left icon. */
      leftIcon?: TablerIconElement;
      /** Right icon. */
      rightIcon?: TablerIconElement;
      /** Action that will be rendered on the right of the input, left of the right icon if any. */
      action?: ReactElement<InputActionProps, JSXElementConstructor<InputActionProps>>;
    },
  ]
>;

const InputForwardRef = forwardRef(function Input(props: InputProps, ref: ForwardedRef<HTMLInputElement>) {
  const { size: formLayoutSize } = useFormLayoutContext() || {};
  const { disabled: formContextDisabled } = useFormContext();

  const {
    formFieldProps,
    otherProps: {
      size = formLayoutSize ? FORM_LAYOUT_SIZE_INPUT_SIZE_MAPPING[formLayoutSize] : InputSize.MEDIUM,
      onChange = noop,
      leftIcon = undefined,
      rightIcon = undefined,
      disabled = false,
      action = undefined,
      step = 'any', // For number inputs, by default only integers are allowed.
      ...otherProps
    },
  } = useFormFieldProps({ ...props, disabled: props.disabled || formContextDisabled });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => onChange(event.currentTarget.value),
    [onChange],
  );

  return (
    <FormField
      {...formFieldProps}
      size={INPUT_SIZE_FIELD_SIZE_MAPPING[size]}
    >
      <div
        className={clsx(size, { [styles.IS_DISABLED_CLASSNAME]: disabled })}
        css={styles.container}
      >
        <input
          {...otherProps}
          ref={ref}
          data-1p-ignore
          data-bwignore
          data-lpignore
          aria-invalid={otherProps['aria-invalid'] || !!formFieldProps.error || undefined}
          css={styles.input}
          data-form-type={(otherProps['data-form-type' as keyof typeof otherProps] as string) || 'other'} // Prevent Dashlane from autofilling.
          disabled={disabled}
          step={step}
          className={clsx(otherProps.className, {
            [styles.HAS_LEFT_ICON_CLASSNAME]: !!leftIcon,
            [styles.HAS_RIGHT_ICON_CLASSNAME]: !!rightIcon,
            [styles.HAS_ERROR_CLASSNAME]: !!formFieldProps.error,
            [styles.HAS_ACTION_CLASSNAME]: !!action,
          })}
          onChange={handleChange}
        />

        {!!leftIcon && (
          <div
            className={clsx(size)}
            css={[styles.iconContainer, styles.leftIcon]}
          >
            {cloneElement(leftIcon, { color: 'currentColor' })}
          </div>
        )}

        {!!action && (
          <div css={styles.action}>
            {cloneElement(action, {
              size,
              disabled: disabled || action.props.disabled,
            })}
          </div>
        )}

        {!!rightIcon && (
          <div
            className={clsx(size)}
            css={[styles.iconContainer, styles.rightIcon]}
          >
            {cloneElement(rightIcon, { color: 'currentColor' })}
          </div>
        )}
      </div>
    </FormField>
  );
});

export const Input = Object.assign(memo(InputForwardRef), {
  Action: InputAction,
  ClearAction: InputClearAction,
  Size: InputSize,
});
