import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

export const customObjectDefinitionsQueryKeys = {
  all: () => ['custom-object-definitions'] as const,
  listReal: () => [...customObjectDefinitionsQueryKeys.all(), 'list-real'] as const,
  listVirtual: () => [...customObjectDefinitionsQueryKeys.all(), 'list-virtual'] as const,
  detailsVirtual: (machineName: CustomObjectDefinition['machineName']) =>
    [...customObjectDefinitionsQueryKeys.all(), 'details-virtual', machineName] as const,
  detailsReal: (machineName: CustomObjectDefinition['machineName']) =>
    [...customObjectDefinitionsQueryKeys.all(), 'details-real', machineName] as const,
} as const;

export const customObjectDefinitionsMutationKeys = {
  all: () => ['custom-object-definitions'] as const,
} as const;
