import { type ForwardedRef, forwardRef } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { Input, type InputProps } from '../Input';

export type InputTypeProp = 'email' | 'number' | 'password' | 'text' | 'url' | undefined;

export type FormikInputProps<TType extends InputTypeProp = 'text'> = FormikFieldProps<InputProps, TType>;

const FormikInputBase = forwardRef(function FormikInput<TType extends InputTypeProp = 'text'>(
  {
    validate, // Omit validate and any props not passed to Input.
    type = 'text' as TType,
    ...props
  }: FormikInputProps<TType>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { onChange, ...formikFieldProps } = useFormikFieldAdapter<InputProps['value'], TType>({
    ...props,
    validate,
    type,
  });

  return (
    <Input
      {...props}
      {...formikFieldProps}
      ref={ref}
      type={type}
      onChange={onChange as (value: string) => void}
    />
  );
});

export const FormikInput = Object.assign(FormikInputBase, {
  Action: Input.Action,
  ClearAction: Input.ClearAction,
  Size: Input.Size,
});
